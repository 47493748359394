


































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { EmailAddress, EmailText, Profile } from '@/models';
import {
  EmailAddressService,
  EmailTextService,
  ProfileService,
} from '@/lib/services';
import { unwrapError } from '@/lib/helpers';
import { ProfileEmailSettings } from '@/components/profile/index';

@Component
export default class ProfileEditEmail extends Vue {
  @Prop({ required: true, type: Profile })
  profile: Profile;
  editing: ProfileEmailSettings | null = null;

  fromEmails: EmailAddress[] = [];
  ccEmails: EmailAddress[] = [];
  textOptions: EmailText[] = [];

  async created(): Promise<void> {
    this.fromEmails = (await EmailAddressService.all('from')).filter(
      (e) => e.verified,
    );
    this.ccEmails = await EmailAddressService.all('cc');
    this.textOptions = await EmailTextService.all();
  }

  mounted(): void {
    this.setupEditing();
  }

  setupEditing(): void {
    this.editing = {
      emailfrom: this.profile.fromEmailId,
      emailcc: this.profile.ccEmailId,
      emailtext: this.profile.emailTextId,
      emailtextreminder: this.profile.emailTextReminderId,
      emailtextquotation: this.profile.emailTextQuotationId,
    };
  }

  async save(): Promise<void> {
    if (!this.editing) return;
    try {
      const updated = await ProfileService.update(
        this.profile.id,
        this.editing,
      );
      Object.assign(this.profile, updated);
      this.setupEditing();
      this.$toaster.success(this.$tc('messages.success.update.profile'));
      this.$router.push('/settings/profile/');
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.update.profile'),
        unwrapError(e),
      );
    }
  }
}
