

































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import Stepper from './Stepper.vue';

@Component
export default class Step extends Vue {
  @Prop({ default: false })
  active!: boolean;

  @Prop({ required: true })
  title: string;

  @Prop({ default: true })
  renderButtons: boolean;

  isActive: boolean = this.active;
  currentActive: number = 0;

  created(): void {
    this.parent.steps.push(this);
    this.isActive = this.active;
  }

  goto(): void {
    this.parent.select(this.index);
  }

  beforeDestroy(): void {
    this.parent.steps.slice(this.index, 1);
  }

  get index(): number {
    return this.parent.steps.indexOf(this);
  }

  get parent(): Stepper {
    return this.$parent as Stepper;
  }

  get circleClasses(): Record<string, boolean> {
    return {
      'is-highlighted': this.currentActive >= this.index,
    };
  }

  get titleClasses(): Record<string, boolean> {
    return {
      'is-bold': this.isActive,
    };
  }

  next(): void {
    this.$emit('next');
    this.parent.next();
  }

  prev(): void {
    this.$emit('prev');
    this.parent.prev();
  }

  @Watch('$parent.activeStep')
  onStepSelect(idx: number): void {
    if (this.isActive && this.index !== idx) {
      this.$emit('close');
    }

    this.isActive = this.index === idx;
    this.currentActive = idx;
  }
}
