



























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ProfileSmsSettings } from '@/components/profile/index';
import { ProfileService, SmsService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';
import { Profile, SmsText } from '@/models';

@Component
export default class ProfileEditSms extends Vue {
  @Prop({ required: true, type: Profile })
  profile: Profile;
  editing: ProfileSmsSettings | null = null;

  textOptions: SmsText[] = [];

  async created(): Promise<void> {
    this.textOptions = await SmsService.listTexts();
  }

  mounted(): void {
    this.setupEditing();
  }

  setupEditing(): void {
    this.editing = {
      smstext: this.profile.smsTextId,
    };
  }

  async save(): Promise<void> {
    if (!this.editing) return;
    try {
      const updated = await ProfileService.update(
        this.profile.id,
        this.editing,
      );
      Object.assign(this.profile, updated);
      this.setupEditing();
      this.$toaster.success(this.$tc('messages.success.update.profile'));
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.update.profile'),
        unwrapError(e),
      );
    }
  }
}
