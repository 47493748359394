






import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Step from './Step.vue';

@Component
export default class Stepper extends Vue {
  steps: Step[] = [];
  activeStep: number = 0;

  mounted(): void {
    this.select(0);
    this.activeStep = this.getInitialActiveStep();
  }

  public next(): void {
    if (this.activeStep < this.steps.length) {
      this.activeStep += 1;
      this.$emit('next');
      this.$emit('step');
    }
    if (this.activeStep === this.steps.length) {
      this.$emit('done');
    }
  }

  public prev(): void {
    if (this.activeStep > 0) {
      this.activeStep -= 1;
      this.$emit('prev');
      this.$emit('step');
    }
  }

  isActive(idx: number): boolean {
    return this.activeStep === idx;
  }

  select(idx: number): void {
    this.activeStep = idx;
    this.$emit('step');
  }

  getInitialActiveStep(): number {
    const idx = this.steps.findIndex((e) => e.active);
    return idx === -1 ? 0 : idx;
  }
}
