












































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { Profile } from '@/models';
import { ProfileService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

@Component
export default class ProfileEditDetails extends Vue {
  @Prop({ required: true, type: Profile })
  profile: Profile;
  editing: Profile | null = null;

  mounted(): void {
    this.editing = Object.assign(new Profile(), this.profile);
  }

  async save(): Promise<void> {
    if (!this.editing) return;
    try {
      const updated = await ProfileService.update(
        this.profile.id,
        this.editing.serialize(),
      );
      this.profile.lines = this.editing.lines = updated.lines;
      this.$toaster.success(this.$tc('messages.success.update.profile'));
      this.$router.push('/settings/profile/');
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.update.profile'),
        unwrapError(e),
      );
    }
  }
}
