










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { AuthService, ProfileService } from '@/lib/services';
import Vue2Dropzone from 'vue2-dropzone';
import { Profile } from '@/models';

import TnfConfig from '@/config';

const sAuth = namespace('auth');

@Component({
  components: {
    Vue2Dropzone,
  },
})
export default class ProfileLogoUpload extends Vue {
  @Prop({ required: true, type: Profile })
  profile: Profile;

  @sAuth.State('token') authToken;

  // To help with dropzone's retardedness
  isDestroying: boolean = false;
  isReplacing: boolean = false;

  beforeDestroy(): void {
    this.isDestroying = true;
  }

  onSuccess(file: File): void {
    const ext = file.name.split('.').pop();
    this.profile.logo = `${this.profile.id}.${ext}`;
  }

  get dropzoneOptions(): Record<string, any> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vue = this;
    const prof = this.profile;
    return {
      url: `${TnfConfig.api.base}/settings/profile/${this.profile.id}/logo`,
      thumbnailWidth: 300,
      maxFiles: 2,
      addRemoveLinks: true,
      headers: {
        Authorization: `Bearer ${this.authToken}`,
      },
      init() {
        const dz = this as any;

        dz.on('addedfile', (file) => {
          if (dz.files.length > 1) {
            vue.isReplacing = true;
            dz.removeAllFiles(true);
            dz.addFile(file);
          }
        });

        if (prof.logo) {
          ProfileService.getLogo(prof.logo).then((logodata) => {
            const current = { name: prof.logo, size: 1 };
            dz.options.addedfile.call(dz, current);
            dz.files.push(current);
            dz.options.thumbnail.call(dz, current, logodata);
            dz.options.complete.call(dz, current);
          });
        }
      },
    };
  }

  async deleteLogo(): Promise<void> {
    if (this.isDestroying) return;
    if (this.isReplacing) {
      this.isReplacing = false;
      return;
    }
    await AuthService.getAxios().delete(
      `/settings/profile/${this.profile.id}/logo`,
    );
    this.profile.logo = null;
  }
}
