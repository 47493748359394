



























































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { Profile } from '@/models';
import { ProfileService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

import { ProfileInvoiceSettings } from '@/components/profile/index';
import { Language } from '@/plugins/i18n';

@Component
export default class ProfileEditInvoice extends Vue {
  @Prop({ required: true, type: Profile })
  profile: Profile;
  editing: ProfileInvoiceSettings | null = null;

  mounted(): void {
    this.setupEditing();
  }

  setupEditing(): void {
    this.editing = {
      language: this.profile.language,
      expiration: this.profile.expiration,
      extratext: this.profile.extraText,
      extratextbottom: this.profile.extraTextBottom,
      footer: this.profile.footer,
      quotationfooter: this.profile.footerQuotation,
      logo_position: this.profile.logoPosition || 'left',
      sender_position: this.profile.senderPosition || 'right',
      margin_global: this.profile.marginGlobal || 10,
      margin_header: this.profile.marginHeader || 0,
    };
  }

  get languageOptions(): Record<string, string>[] {
    return [
      { name: this.$tc('_.lang.nl'), value: Language.NL },
      { name: this.$tc('_.lang.en'), value: Language.EN },
      { name: this.$tc('_.lang.de'), value: Language.DE },
      { name: this.$tc('_.lang.fr'), value: Language.FR },
    ];
  }

  async save(): Promise<void> {
    if (!this.editing) return;
    try {
      const updated = await ProfileService.update(
        this.profile.id,
        this.editing,
      );
      Object.assign(this.profile, updated);
      this.setupEditing();
      this.$toaster.success(this.$tc('messages.success.update.profile'));
      this.$router.push('/settings/profile/');
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.update.profile'),
        unwrapError(e),
      );
    }
  }
}
